import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    redirect:"/home",
    children:[
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../components/Home.vue'),
      },
      {
        path: '/modelList',
        name: 'modelList',
        component: () => import(/* webpackChunkName: "about" */ '../components/modelList.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior:()=>{
    history.pushState(null,null,document.URL)
  }
})

// 导航守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let username = sessionStorage.getItem('username');
    if (username === null || username === '') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
