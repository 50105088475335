
import http from "./http.js";

let apiFun={}

//登录
apiFun.login=function (params){
    return http.post("/login",params)
}

//上传
apiFun.upload=function (params){
    return http.post("/upload",params)
}

//训练
apiFun.train=function (params){
    return http.post("/train",params)
}
//训练
apiFun.predict=function (params){
    return http.post("/predict",params)
}

//模型列表
apiFun.modelsList=function (params){
    return http.get("/modelsList",params)
}

//下载模型
apiFun.downloadfile=function (params){
    return http.get("/downloadfile",params)
}
export default apiFun;