import axios from "axios"

export const baseURL = 'http://3vh7lcq5.shenzhuo.vip:56578'
//export const baseURL = 'http://127.0.0.1:5000'
let config = {
    baseURL: baseURL,
    timeout: 30000       //设置最大请求时间
}
const _axios = axios.create(config)
// /* 请求拦截器（请求之前的操作） */
// _axios.interceptors.request.use(
//     config=>{
//         config.headers.Authorization = "getToken";
//         return config;
//     },
//     err=>Promise.reject(err)
// )
/* 请求之后的操作 */
// _axios.interceptors.response.use(
//     res=>{
//         console.log(res)
//     }
// )

//封装post方法
const http={
    post(url="",params={}) {
        return new Promise(
            (resolve)=>{
                _axios({
                    url,
                    data:params,
                    headers:{'Content-Type': 'application/json;charset=UTF-8'},
                    method: 'POST'
                }).then(res=>{
                    resolve(res.data)
                    return res
                }).catch(res=>{
                    resolve(res.data)
                    return res
                })
            }
        )
    },
    get(url="",params={}) {
        return new Promise(
            (resolve)=>{
                _axios({
                    url,
                    params:params,
                    headers:{'Content-Type': 'application/json;charset=UTF-8'},
                    method: 'GET'
                }).then(res=>{
                    resolve(res.data)
                    return res
                }).catch(res=>{
                    resolve(res.data)
                    return res
                })
            }
        )
    },

}

export default http
