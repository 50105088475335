import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "../src/assets/aliicon/iconfont.css"
// import "../src/assets/aliicon/iconfont.js"
import apiFun from "../src/assets/api/api.js";
Vue.prototype.$apiFun = apiFun;//请求接口api

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


